import React, { useState } from "react";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [msg, setMsg] = useState("");

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    // For now, let's just log the data and show a thank you message
    console.log(formData);
    setMsg("Thank you for contacting us. We will get back to you soon.");
    setTimeout(() => {
      window.location.href = "ariselogistic.com/request-a-quote.aspx";
    }, 2000);
  };

  return (
    <section id="section">
      {/* Section box starts Here */}
      <div className="section">
        <div className="contact-form">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6">
                <div className="heading">
                  <span>our</span>
                  <h3>contact form</h3>
                </div>
                <div className="contact-form-box">
                  <form
                    name="contactForm"
                    id="contactForm"
                    onSubmit={handleSubmit}
                  >
                    <div id="success"></div>
                    <div className="row" id="contactusform">
                      <input
                        id="name"
                        className="contact-name"
                        type="text"
                        placeholder="Name*"
                        value={formData.name}
                        onChange={handleChange}
                      />
                      <input
                        id="email"
                        className="contact-mail"
                        type="text"
                        placeholder="Email*"
                        required
                        value={formData.email}
                        onChange={handleChange}
                      />
                      <input
                        id="subject"
                        className="contact-subject"
                        type="text"
                        placeholder="Subject*"
                        value={formData.subject}
                        onChange={handleChange}
                      />
                      <textarea
                        placeholder="Comment*"
                        id="message"
                        value={formData.message}
                        onChange={handleChange}
                      ></textarea>
                      <button type="submit" className="comment-submit">
                        Submit
                      </button>
                    </div>
                    <div style={{ float: "right", color: "red" }}>
                      <span>{msg}</span>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6">
                <div
                  className="map-box"
                  id="map-box"
                  style={{ marginTop: "100px" }}
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3424.1242892080086!2d75.90985931446559!3d30.88318388541844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391a9d3a7cf7491d%3A0x21de1ababfd048ff!2sOwpl%20Complex!5e0!3m2!1sen!2sin!4v1623824692308!5m2!1sen!2sin"
                    width="600"
                    height="450"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="location parallax">
          <div className="">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="heading">
                    <span>more</span>
                    <h3>locations</h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div id="location-slides" className="location-slider">
                  <div className="location-slide-tab">
                    <h6>Delhi</h6>
                    <address></address>
                    <span className="call">
                      call: <a href="tel:7307537004">7307537004</a>
                    </span>
                    <span className="location-email">
                      email:{" "}
                      <a href="mailto:expresscargomovers786@gmail.com">
                        expresscargomovers786@gmail.com
                      </a>
                    </span>
                  </div>
                  <div className="location-slide-tab">
                    <h6>Nhava Sheva</h6>
                    <address></address>
                    <span className="call">
                      call: <a href="tel:7307537004">7307537004</a>
                    </span>
                    <span className="location-email">
                      email:{" "}
                      <a href="mailto:expresscargomovers786@gmail.com">
                        expresscargomovers786@gmail.com
                      </a>
                    </span>
                  </div>
                  <div className="location-slide-tab">
                    <h6>Mundra</h6>
                    <address></address>
                    <span className="call">
                      call: <a href="tel:7307537004">7307537004</a>
                    </span>
                    <span className="location-email">
                      email:{" "}
                      <a href="mailto:expresscargomovers786@gmail.com">
                        expresscargomovers786@gmail.com
                      </a>
                    </span>
                  </div>
                  {/* Add more locations as needed */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section box ends Here */}
    </section>
  );
};

export default ContactUs;
