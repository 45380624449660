import React, { useState } from "react";

const RequestQuote = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    mailid: "",
    city: "",
    service: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log("Form submitted:", formData);
  };

  return (
    <section id="section">
      <div className="section">
        <div className="quote-form">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="heading">
                  <span>our</span>
                  <h3>quote form</h3>
                </div>
                <div className="quote-form-box">
                  <form
                    name="requestForm"
                    id="requoteform"
                    onSubmit={handleSubmit}
                  >
                    <div className="success" id="success"></div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 right-space">
                        <input
                          id="name"
                          className="quote-name"
                          name="name"
                          type="text"
                          placeholder="Name*"
                          value={formData.name}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 left-space">
                        <input
                          id="phone"
                          className="quote-phone"
                          name="phone"
                          type="text"
                          required
                          minLength="10"
                          maxLength="10"
                          placeholder="Phone*"
                          value={formData.phone}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 right-space">
                        <input
                          id="mailid"
                          className="quote-mail"
                          name="mailid"
                          type="text"
                          required
                          placeholder="Email*"
                          value={formData.mailid}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 left-space">
                        <input
                          id="city"
                          className="quote-city"
                          name="city"
                          type="text"
                          placeholder="City*"
                          value={formData.city}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 right-space">
                        <input
                          id="service"
                          className="quote-service"
                          name="service"
                          type="text"
                          placeholder="Service*"
                          value={formData.service}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6 left-space">
                        <input
                          id="subject"
                          className="quote-subject"
                          name="subject"
                          type="text"
                          placeholder="Subject*"
                          value={formData.subject}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-xs-12">
                        <textarea
                          id="msg"
                          name="message"
                          placeholder="Comment*"
                          value={formData.message}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                      <div className="col-xs-12">
                        <button
                          type="submit"
                          className="comment-submit qoute-sub"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                    <div className="error error-msg"></div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="office-box">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-6 custom-zero-padding">
                <div className="head-office">
                  <div className="clearfix">
                    <span>
                      <i className="icon-envelope"></i>
                    </span>
                    <div className="heading">
                      <span>contact</span>
                      <h3>head office</h3>
                    </div>
                    <div className="address-bar">
                      <span className="small-heading">address</span>
                      <address>
                        #220A, 2nd Floor,
                        <br />
                        OWPL complex, phase-V
                        <br />
                        FOCAL POINT, LUDHIANA-141010
                      </address>
                      <div className="phone-bar">
                        <span className="small-heading">phone</span>
                        <a href="tel:7307537004">7307537004</a>
                      </div>
                      <div className="phone-bar">
                        <span className="small-heading">email</span>
                        <span>
                          email :{" "}
                          <a href="mailto:expresscargomovers786@gmail.com">
                            expresscargomovers786@gmail.com
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-6 custom-zero-padding">
                <div className="timing">
                  <span>
                    <i className="icon-alarm"></i>
                  </span>
                  <div className="heading">
                    <span>Office</span>
                    <h3>Hours</h3>
                  </div>
                  <ul>
                    <li>
                      monday{" "}
                      <span>
                        : <strong>9.30 am - 6.00 pm</strong>
                      </span>
                    </li>
                    <li>
                      tuesday{" "}
                      <span>
                        : <strong>9.30 am - 6.00 pm</strong>
                      </span>
                    </li>
                    <li>
                      wednesday{" "}
                      <span>
                        : <strong>9.30 am - 6.00 pm</strong>
                      </span>
                    </li>
                    <li>
                      thursday{" "}
                      <span>
                        : <strong>9.30 am - 6.00 pm</strong>
                      </span>
                    </li>
                    <li>
                      friday{" "}
                      <span>
                        : <strong>9.30 am - 6.00 pm</strong>
                      </span>
                    </li>
                    <li>
                      saturday{" "}
                      <span>
                        : <strong>9.30 am - 1.00 pm</strong>
                      </span>
                    </li>
                    <li>
                      sunday{" "}
                      <span className="closed">
                        : <strong>closed</strong>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="query">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-10">
                <h5>DO YOU STILL HAVE A QUESTION REGARDING OUR SERVICES?</h5>
                <p>Contact Us if you want to know more about what we do</p>
              </div>
              <div className="col-xs-12 col-sm-2">
                <a href="ContactUs.aspx" className="button contact-us">
                  contact us
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RequestQuote;
