import React from "react";
import { Outlet } from "react-router-dom";
import "./Home.css";

function Home() {
  return (
    <>
      <div className="App">
        {/* <div className="loader-block">
          <div className="loader">Loading...</div> 
        </div>*/}
        {/* Loader Ends here */}
        {/* Wrapper Section Start Here */}
        <div id="wrapper" className="homepage homepage-1">
          {/* header Section Start Here */}
          <header id="header" className="header">
            {/* primary header Start Here */}
            <div className="primary-header" style={{ height: "50px" }}>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <div className="mail">
                      <img src="assests/images/icon-mail.png" alt="" />
                      <span>
                        Email us at :{" "}
                        <a
                          className="email-us"
                          href="mailto:expresscargomovers786@gmail.com"
                        >
                          expresscargomovers786@gmail.com
                        </a>
                      </span>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <div className="social-wrap clearfix">
                      <a
                        href="/request-a-quote"
                        style={{
                          backgroundColor: "rgb(60 16 83) !important",
                          color: "#DF6589FF !important",
                        }}
                        className="request"
                      >
                        request a quote
                      </a>
                      <ul className="social">
                        <li>
                          <a href="#">
                            {" "}
                            <i className="fab fa-facebook"></i>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {" "}
                            <i className="fab fa-twitter"></i>{" "}
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {" "}
                            <i className="fab fa-google-plus"></i>{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* primary header Ends Here */}
            {/* main header Starts Here */}
            <div className="main-header">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 hidden-xs">
                    <div className="call-us">
                      <ul>
                        <li>
                          <img src="assests/images/iphone.png" alt="" />
                          <span className="transport">
                            CALL US NOW FOR <span></span> ANY QUERY
                          </span>
                        </li>
                        <li>
                          <a href="tel:7307537004">7307537004</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-3">
                    <a href="/" className="c-logo logo">
                      {" "}
                      <div className="img-responsive">
                        <img
                          style={{ maxHeight: "120px" }}
                          src="assests/images/explogo.jpeg"
                          alt="Logo"
                        />
                        <div
                          style={{
                            position: "absolute",
                            top: "3px",
                            left: "173px",
                          }}
                        >
                          <h3
                            className="left-align"
                            style={{ color: "#1f8ac0", fontWeight: "900" }}
                          >
                            Express Cargo Movers
                          </h3>
                        </div>{" "}
                      </div>
                    </a>
                  </div>
                  <div className="col-xs-12 col-sm-9 custom-nav">
                    <nav>
                      <div id="cssmenu">
                        <ul class="navigation">
                          <li class="active">
                            <a
                              href="/"
                              style={{ fontFamily: "karla, sans-serif" }}
                            >
                              Home
                            </a>
                          </li>
                          <li>
                            <a href="/aboutUs">about us</a>
                          </li>
                          <li>
                            <a href="/service">Service</a>
                          </li>
                          <li>
                            <a href="#">Tools</a>
                            <ul class="sub-menu">
                              <li>
                                <a href="Container Specification.aspx">
                                  Container Specification
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="https://www.omnicalculator.com/other/cbm-shipping"
                                >
                                  CBM Calculator
                                </a>
                              </li>
                              <li>
                                <a target="_blank" href="https://www.nicdc.in/">
                                  Container Tracking
                                </a>
                              </li>
                              <li>
                                <a
                                  target="_blank"
                                  href="http://cbic.gov.in/excange-rate-notifications"
                                >
                                  Exchange Rate
                                </a>
                              </li>
                            </ul>
                          </li>

                          <li>
                            <a href="/contactUs">contactUS</a>
                          </li>
                          <li>
                            <a href="/carrier">Career</a>
                          </li>
                        </ul>
                      </div>
                    </nav>

                    <div className="nav-icon">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* main header Ends Here */}
          </header>

          <div>
            <Outlet />
          </div>
          <footer id="footer">
            {/* Footer area ends Here */}
            <div className="footer clearfix">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-4">
                    <div className="quick-links">
                      <h5>QUICK LINKS</h5>
                    </div>
                    <div className="quick-list">
                      <ul>
                        <li>
                          <a href="#">Custom Clearange</a>
                        </li>
                        <li>
                          <a href="#">air freight</a>
                        </li>
                        <li>
                          <a href="#">sea freight</a>
                        </li>
                        <li>
                          <a href="#">storage &amp; packaging</a>
                        </li>
                      </ul>
                    </div>
                    <div className="quick-list">
                      <ul>
                        <li>
                          <a href="#"></a>
                        </li>
                        <li>
                          <a href="#">logistic solutions</a>
                        </li>
                        <li>
                          <a href="#">cargo shipping</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-4">
                    <div className="our-address">
                      <h5>contact us</h5>
                      <div className="address">
                        <h6>Express Cargo Movers</h6>
                        <address>
                          #220A, 2nd Floor, OWPL Complex, Phase-V, Focal Point,
                          Ludhiana- 141010
                        </address>
                        <div className="phone">
                          <span>
                            phone : <a href="tel:7307537004">7307537004</a>
                          </span>
                          <span>
                            email :{" "}
                            <a href="#">
                              <span className="__cf_email__">
                                expresscargomovers786@gmail.com
                              </span>
                            </a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-4">
                    <div className="sign-up">
                      <h5>newsletter signup</h5>
                      <p>
                        If you want receive our all weekly updates about new
                        offers and discount, signup below.
                      </p>
                      <form>
                        <input
                          id="mail"
                          type="text"
                          placeholder="Email Address"
                          name="email"
                        />
                        {/* <input id="submit" type="submit" value="" className="fa fa-paper-plane-o" /> */}
                        <button className="fa fa-paper-plane"></button>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="row custom-row">
                  <div className="col-xs-12 col-sm-5">
                    <div className="copyright">
                      <span>All Rights Reserved</span>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-7 no-wrap-mobile">
                    <div className="footer-nav">
                      <ul>
                        <li>
                          <a href="#">terms of use</a>
                        </li>
                        <li>
                          <a href="#">legal desclaimer</a>
                        </li>
                        <li>
                          <a href="#">privacy policy</a>
                        </li>
                        <li>
                          <a href="#">support</a>
                        </li>
                        <li>
                          <a href="#">sitemap</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer box ends Here */}
          </footer>
          {/* Footer area ends Here */}
        </div>
      </div>
    </>
  );
}

export default Home;
