import React from "react";

const Carrier = () => {
  return (
    <div className="container">
      <div className="row" style={{ marginBottom: "5px" }}>
        <div className="col-xs-12 col-sm-4">
          <img src="assests/images/career.jpg" alt="Career" />
        </div>
        <div className="col-xs-12 col-sm-8">
          <div className="amazing-text">
            <div className="heading">
              <span>Express Cargo Movers</span>
              <h3>Join Us</h3>
            </div>
            <p>
              <strong>
                We are always looking for passionate professionals who wish to
                contribute to the growth of the organization along with
                achieving their goals.
              </strong>
              <br />
              <br />
              At Express Cargo Movers, we believe in being an employer that
              ensures the benefits and satisfaction of all our employees. We
              create an atmosphere that recognizes the contribution of every
              employee and make sure that his / her worth is suitably rewarded
              through the best perks, facilities and growth opportunities.
              <br />
              <br />
              No wonder then, that Express Cargo Movers is a place all our
              employees love to belong to. Their happiness quotient is reflected
              in their efficiency and hence, the ever-growing success story of{" "}
              <strong>Express Cargo Movers!</strong>
              <br />
              <br />
              If you too would like to have the{" "}
              <strong>Express Cargo Movers</strong> experience – competitive
              remunerations, extensive training and value-of-worth, please
              contact{" "}
              <a href="mailto:expresscargomovers786@gmail.com">
                expresscargomovers786@gmail.com
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrier;
