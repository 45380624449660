import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import Home from "./Component/Header/Home";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Main from "./Component/Main/Main";
import About from "./Component/About/About";
import Services from "./Component/Service/Service";
import ContactUs from "./Component/ContactUs/ContactUs";
import Carrier from "./Component/Carrier/Carrier";
import RequestQuote from "./Component/RequestQuote/Request_a_Quote";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="/" element={<Main />}></Route>
          <Route path="/aboutUs" element={<About />}></Route>
          <Route path="/service" element={<Services />}></Route>
          <Route path="/contactUs" element={<ContactUs />}></Route>
          <Route path="/carrier" element={<Carrier />}></Route>
          <Route path="/request-a-quote" element={<RequestQuote />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
