import React from "react";

function About() {
  return (
    <>
      <section id="section">
        {/* Section box starts Here  */}
        <div className="section">
          <div className="amazing-features ">
            <div className="container">
              <div className="row" style={{ marginBottom: 5 }}>
                <div className="col-xs-12 col-sm-4">
                  <img src="assests/images/truck.jpg" alt="" />
                </div>
                <div className="col-xs-12 col-sm-8">
                  <div className="amazing-text">
                    <div className="heading">
                      <span>Express Cargo Movers</span>
                      <h3>about us</h3>
                    </div>
                    <p>
                      Established in, 2016 Express Cargo Movers is the flagship
                      company of a multifaceted corporate house and today has
                      become a conglomerate of individual business entities with
                      a strong presence in multi-modal international logistics.
                      With our impeccable financial dealings and standing in the
                      trade, we have carved for ourselves a niche place in
                      Indian market. We are continuously striving to provide
                      logistics solutions to our customers which give them the
                      leading edge information on their products. We have a
                      broad menu of services that covers the whole supply chain
                      management and on-time information on the shipments.
                      Express Cargo Movers is one of the leading Freight
                      Forwarding Company & Customs Broker in India with the
                      group having established a reputation of efficient and
                      reliable International Logistics we providing both Origin
                      & Destination services pertaining to all kinds of cargo.
                      Express Cargo Movers with its worldwide network of
                      Efficient Overseas Partners Strategically Located Offices
                      is well poised to deliver your cargo timely, safely and
                      reliably in an efficient manner. Having unmatched
                      financial & professional integrity Express Cargo Movers
                      commands a High Level of respect from its corporate and
                      individual clients as a Cohesive Team Work who Delivers
                      Time and Again against under challenging situations..
                    </p>
                    <a href="ContactUs.aspx" className="button ">
                      contact us
                    </a>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginBottom: 5 }}>
                <div className="col-xs-12 col-sm-8">
                  <div className="amazing-text">
                    <div className="heading">
                      <span>Express Cargo Movers</span>
                      <h3>Corporate Vision</h3>
                    </div>
                    <p>
                      The global business environment is complex one as success
                      depends on the ability of company executives to make key
                      decisions quickly. Subsequently, in order to meet their
                      client’s demands, those in the logistics industry have to
                      be more flexible, innovative and customer focused whilst
                      at the same time providing competitive rates. No Company
                      understands this better than Express Cargo. Since its
                      inception in 2016, our team has had a passion and
                      determination for providing customers with an unbeatable
                      service. From those who do the planning, tracking and
                      checking to those who carry out the physical execution of
                      the shipment, each has an eye for detail and is an expert
                      in their field. Together, we are here to make the shipping
                      of your goods a smooth and trouble-free operation. We have
                      endeavored to provide you with as complete a picture as
                      possible about the capabilities of Express Cargo Movers.
                      Nevertheless, we know that no presentation can compare to
                      talking. So, for us to provide you with the swift
                      tailor-made services you need, we want to understand the
                      specific requirement of your company. You are the most
                      important people to us and we are not satisfied unless you
                      are. Our aim of satisfying our customers is present in all
                      our daily Operations and it is why our clients return
                      again and again …….
                      <br />
                      Trust Us to Deliver!!!
                    </p>
                    <a href="ContactUs.aspx" className="button ">
                      contact us
                    </a>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-4">
                  <img src="assests/images/corporatevision.jpg" alt="" />
                </div>
              </div>
              <div className="row" style={{ marginBottom: 5 }}>
                <div className="col-xs-12 col-sm-4">
                  <img
                    src="assests/images/qualitypolicy.jpg"
                    alt=""
                    style={{ marginBottom: 5 }}
                  />
                </div>
                <div className="col-xs-12 col-sm-8">
                  <div className="amazing-text">
                    <div className="heading">
                      <span>Express Cargo Movers</span>
                      <h3>Goals and Quality Policy</h3>
                    </div>
                    <p>
                      To be recognized as a Total Global Logistics Company by
                      providing innovative, reliable and tailor-maid logistics
                      solutions to our customers.
                      <br />
                      <br />
                      Express Cargo is committed to Total Customer Satisfaction
                      by providing Efficient and Cost-Effective Solutions to our
                      International Freight Forwarding Needs and Fast Track
                      Customs Clearance.
                    </p>
                    <a href="ContactUs.aspx" className="button ">
                      contact us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="features ">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="features-text">
                    <div className="heading">
                      <span>AMAZING FEATURES</span>
                      <h3>QUICKES DELIVERY</h3>
                    </div>

                    <p></p>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="features-tab bottom-border">
                    <i className="icon-ship"></i>
                    <div className="tab-text">
                      <h5>QUICKEST CARGO</h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="features-tab bottom-border">
                    <i className="icon-plane"></i>
                    <div className="tab-text">
                      <h5>GROUND SHIPPING</h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="features-tab">
                    <i className="icon-truck"></i>
                    <div className="tab-text">
                      <h5>cargo van available</h5>
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className="features-tab bottom-border">
                    <i className="icon-train"></i>
                    <div className="tab-text">
                      <h5>FAST AIR FREIGHT</h5>
                      <p></p>
                    </div>
                  </div>
                  <div className="features-tab bottom-border">
                    <i className="icon-clock"></i>
                    <div className="tab-text">
                      <h5>TIMELY DELIVERY</h5>
                      <p></p>
                    </div>
                  </div>

                  <div className="features-tab">
                    <i className="icon-globe"></i>
                    <div className="tab-text">
                      <h5>worldwide service</h5>
                      <p></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="team ">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="heading">
                    <span>memebers</span>
                    <h3>Little About Our Management</h3>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 custom-margin-bottom">
                  <div className="col-xs-12 col-sm-12 col-md-12 ">
                    <div className="members zoom clearfix">
                      <div className="figure-wrap">
                        <figure>
                          <a href="#">
                            {" "}
                            <img
                              src="assests/images/member-1.jpg"
                              alt=""
                            />{" "}
                          </a>
                        </figure>
                        <div className="figure-overlay">
                          <ul>
                            <li>
                              <a href="#">
                                <span className="fa fa-facebook"></span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <span className="fa fa-twitter"></span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <span className="fa fa-google-plus"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="members-text">
                        <h6>Jade Samuels</h6>
                        <p>
                          Jade Samuels is the CEO and cofounder of KJS Logistics
                          LLC. As CEO Jade pushes to lead KJS into the future
                          and hopes to encourage the next generation of young
                          entrepreneurs in the process. Her many interests
                          include reading, research, and exercise, but most
                          importantly community outreach. Jade earned her
                          Bachelor's Degree in Business Management from Kean
                          University in Union New Jersey and has used that
                          experience along with her 8+ year career in marketing
                          to chase her dreams and establish KJS. Her overall
                          mission is to motivate, encourage and educate!
                        </p>
                      </div>
                    </div>
                    <p>
                      Our Management is expert in this field having an
                      experience of number of years. The employees are well
                      experienced in their areas of operation. We strive to
                      deliver a level of service that exceeds the expectations
                      of our customers.
                    </p>
                    <blockquote style={{ textAlign: "center" }}>
                      We are experienced... And Old... ...but born new in this
                      competitive world
                    </blockquote>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-12 custom-margin-bottom">
                    <div className="members zoom clearfix">
                      <div className="figure-wrap">
                        <figure>
                          <a href="#">
                            {" "}
                            <img
                              src="assests/images/member-2.jpg"
                              alt=""
                            />{" "}
                          </a>
                        </figure>
                        <div className="figure-overlay">
                          <ul>
                            <li>
                              <a href="#">
                                <span className="fa fa-facebook"></span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <span className="fa fa-twitter"></span>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <span className="fa fa-google-plus"></span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="members-text">
                        <h6>Krissi Judd</h6>
                        <p>
                          Krissi Judd is the COO and cofounder of KJS Logistics
                          LLC. As COO Krissi constantly uses her forward
                          thinking ideas to propel the company forward. Krissi
                          received her Bachelor’s Degree from Widener University
                          in Pennsylvania and her Masters Degree from Ashford
                          University. Her 14+ year career serving veterans in
                          the city of Philadelphia shows her sense of
                          determination and commitment to any and everything
                          that she does. Her many interests include traveling
                          and exploring and creating experiences that will last
                          a lifetime. Her overall mission is to inspire!
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-xs-12 col-sm-6 col-md-4 custom-margin-bottom">
									<div className="members zoom clearfix">
										<div className="figure-wrap">
											<figure>
												<a href="#"> <img src="assests/images/member-3.jpg" alt="" /> </a>
											</figure>
											<div className="figure-overlay">
												<ul>
													<li>
														<a href="#"><span className="fa fa-facebook"></span></a>
													</li>
													<li>
														<a href="#"><span className="fa fa-twitter"></span></a>
													</li>
													<li>
														<a href="#"><span className="fa fa-google-plus"></span></a>
													</li>
												</ul>
											</div>
										</div>
										<div className="members-text">
											<h6>cistafer man</h6>
											<p>
												Lorem ipsum dolor sit amet, consctetur adipiscing elit. Aenean in ante magna.
											</p>
										</div>
									</div>
								</div>
								<div className="col-xs-12 col-sm-6 col-md-4 custom-margin-bottom">
									<div className="members zoom clearfix">
										<div className="figure-wrap">
											<figure>
												<a href="#"> <img src="assests/images/member-4.jpg" alt="" /> </a>
											</figure>
											<div className="figure-overlay">
												<ul>
													<li>
														<a href="#"><span className="fa fa-facebook"></span></a>
													</li>
													<li>
														<a href="#"><span className="fa fa-twitter"></span></a>
													</li>
													<li>
														<a href="#"><span className="fa fa-google-plus"></span></a>
													</li>
												</ul>
											</div>
										</div>
										<div className="members-text">
											<h6>Samual John</h6>
											<p>
												Lorem ipsum dolor sit amet, consctetur adipiscing elit. Aenean in ante magna.
											</p>
										</div>
									</div>
								</div>
								<div className="col-xs-12 col-sm-6 col-md-4 custom-margin-bottom">
									<div className="members zoom clearfix">
										<div className="figure-wrap">
											<figure>
												<a href="#"> <img src="assests/images/member-5.jpg" alt="" /> </a>
											</figure>
											<div className="figure-overlay">
												<ul>
													<li>
														<a href="#"><span className="fa fa-facebook"></span></a>
													</li>
													<li>
														<a href="#"><span className="fa fa-twitter"></span></a>
													</li>
													<li>
														<a href="#"><span className="fa fa-google-plus"></span></a>
													</li>
												</ul>
											</div>
										</div>
										<div className="members-text">
											<h6>Maxmagar</h6>
											<p>
												Lorem ipsum dolor sit amet, consctetur adipiscing elit. Aenean in ante magna.
											</p>
										</div>
									</div>
								</div>
								<div className="col-xs-12 col-sm-6 col-md-4 custom-margin-bottom">
									<div className="members zoom clearfix">
										<div className="figure-wrap">
											<figure>
												<a href="#"> <img src="assests/images/member-6.jpg" alt="" /> </a>
											</figure>
											<div className="figure-overlay">
												<ul>
													<li>
														<a href="#"><span className="fa fa-facebook"></span></a>
													</li>
													<li>
														<a href="#"><span className="fa fa-twitter"></span></a>
													</li>
													<li>
														<a href="#"><span className="fa fa-google-plus"></span></a>
													</li>
												</ul>
											</div>
										</div>
										<div className="members-text">
											<h6>lissi jorge</h6>
											<p>
												Lorem ipsum dolor sit amet, consctetur adipiscing elit. Aenean in ante magna.
											</p>
										</div>
									</div> 
                                    </div>*/}
                </div>
              </div>
            </div>
            <div className="testimonial ">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12">
                    <div className="heading">
                      <span>what people say</span>
                      <h2>testimonials</h2>
                    </div>
                  </div>
                </div>
                <div className="testimonial-text" id="testimonial-text">
                  <div className="feedback">
                    <blockquote className="custom-quote">
                      <span className="fa fa-quote-left custom-fa"></span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc cons entum tempor. Duis porta lectus ut dui
                        consequat, ut porttitor sap um. Nulla sagittis, turpis
                        sit amet feugiat pretium, tellus tortor et consectetur
                        nunc odio at dolor.
                      </p>
                      <footer>
                        <h6>
                          JOHNATHAN DOE <span>DESIGNER, THEME DESIGNER</span>
                        </h6>
                      </footer>
                    </blockquote>
                  </div>
                  <div className="feedback">
                    <blockquote className="custom-quote">
                      <span className="fa fa-quote-left custom-fa"></span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc cons entum tempor. Duis porta lectus ut dui
                        consequat, ut porttitor sap um. Nulla sagittis, turpis
                        sit amet feugiat pretium, tellus tortor et consectetur
                        nunc odio at dolor.
                      </p>
                      <footer>
                        <h6>
                          JOHNATHAN DOE <span>DESIGNER, THEME DESIGNER</span>
                        </h6>
                      </footer>
                    </blockquote>
                  </div>
                  <div className="feedback">
                    <blockquote className="custom-quote">
                      <span className="fa fa-quote-left"></span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc cons entum tempor. Duis porta lectus ut dui
                        consequat, ut porttitor sap um. Nulla sagittis, turpis
                        sit amet feugiat pretium, tellus tortor et consectetur
                        nunc odio at dolor.
                      </p>
                      <footer>
                        <h6>
                          JOHNATHAN DOE <span>DESIGNER, THEME DESIGNER</span>
                        </h6>
                      </footer>
                    </blockquote>
                  </div>
                  <div className="feedback">
                    <blockquote className="custom-quote">
                      <span className="fa fa-quote-left"></span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc cons entum tempor. Duis porta lectus ut dui
                        consequat, ut porttitor sap um. Nulla sagittis, turpis
                        sit amet feugiat pretium, tellus tortor et consectetur
                        nunc odio at dolor.
                      </p>
                      <footer>
                        <h6>
                          JOHNATHAN DOE <span>DESIGNER, THEME DESIGNER</span>
                        </h6>
                      </footer>
                    </blockquote>
                  </div>
                  <div className="feedback">
                    <blockquote className="custom-quote">
                      <span className="fa fa-quote-left"></span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc cons entum tempor. Duis porta lectus ut dui
                        consequat, ut porttitor sap um. Nulla sagittis, turpis
                        sit amet feugiat pretium, tellus tortor et consectetur
                        nunc odio at dolor.
                      </p>
                      <footer>
                        <h6>
                          JOHNATHAN DOE <span>DESIGNER, THEME DESIGNER</span>
                        </h6>
                      </footer>
                    </blockquote>
                  </div>
                  <div className="feedback">
                    <blockquote className="custom-quote">
                      <span className="fa fa-quote-left"></span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Nunc cons entum tempor. Duis porta lectus ut dui
                        consequat, ut porttitor sap um. Nulla sagittis, turpis
                        sit amet feugiat pretium, tellus tortor et consectetur
                        nunc odio at dolor.
                      </p>
                      <footer>
                        <h6>
                          JOHNATHAN DOE <span>DESIGNER, THEME DESIGNER</span>
                        </h6>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>
            <div className="query ">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-sm-10">
                    <h5>DO YOU STILL HAVE A QUESTION REGARING OUR SERVICES?</h5>
                    <p>Give Us a chance to serve you.</p>
                  </div>
                  <div className="col-xs-12 col-sm-2">
                    <a href="ContactUs.aspx" className="button  ">
                      contact us
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Section box ends Here  */}
        </div>
      </section>
    </>
  );
}

export default About;
