import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Main.css";

const below = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
function Main() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  return (
    <>
      <div className="bannercontainer spacetop">
        <Slider {...settings}>
          <div className="banner">
            <img src="assests/images/banner-train.jpg" alt="" />
            <div className="banner-text">
              <div className="caption big_white move-left">
                <a href="services.aspx" className="shipping">
                  Custom Clearance
                </a>
              </div>
              <div className="caption big_orange clearfix move-right">
                <h2>
                  ONE STOP SOLUTION
                  <br />
                  YOUR LOGISTICS
                  <br />
                  ow REQUIREMENTS
                </h2>
              </div>
              <div className="caption medium_grey move-bottom">
                <a href="services.aspx" className="services-link">
                  our services
                </a>
              </div>
            </div>
          </div>
          <div className="banner">
            <img src="assests/images/banner-plane.jpg" alt="" />
            <div className="banner-text">
              <div className="caption big_white move-top">
                <a href="#" className="shipping">
                  Logistic Services
                </a>
              </div>
              <div className="caption big_orange clearfix move-bottom">
                <h2>
                  ONE STOP SOLUTION
                  <br />
                  YOUR LOGISTICS
                  <br />
                  REQUIREMENTS
                </h2>
              </div>
              <div className="caption medium_grey move-left">
                <a href="services.aspx" className="services-link">
                  our services
                </a>
              </div>
            </div>
          </div>
          <div className="banner">
            <img src="assests/images/custom.jpg" alt="" />
            <div className="banner-text">
              <div className="caption big_white move-right">
                <a href="#" className="shipping">
                  Ground Shipping
                </a>
              </div>
              <div className="caption big_orange clearfix move-top">
                <h2>
                  ONE STOP SOLUTION
                  <br />
                  YOUR TRANSPORT
                  <br />
                  REQUIREMENTS
                </h2>
              </div>
              <div className="caption medium_grey move-bottom">
                <a href="service.html" className="services-link">
                  our services
                </a>
              </div>
            </div>
          </div>
        </Slider>
      </div>

      <section id="section">
        <div className="section">
          <div className="services">
            <div className="container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="heading">
                    <h2 className="text-center">LOGISTICS SERVICE</h2>
                    <span className="servicespan">
                      ExpressCargo Movers Agency is handling under a single
                      window system – from ex-works to consignee's site, tailor
                      made to the client's requirement.
                    </span>
                  </div>
                </div>
              </div>
              <div id="services-slides" className="services-slider">
                <Slider {...below}>
                  <div className="slides-tab zoom">
                    <figure>
                      <a href="/service">
                        <img src="assests/images/yard1.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="slides-text">
                      <h4>
                        <a href="/service">Ocean Export & Import Freight</a>
                      </h4>
                      <p>
                        Freight and related services with reliable carriers from
                        worldwide destination.
                      </p>
                      <a href="/service" className="read-more">
                        read more <span>&gt;</span>
                      </a>
                    </div>
                  </div>
                  <div className="slides-tab zoom">
                    <figure>
                      <a href="/service">
                        <img src="assests/images/plane.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="slides-text">
                      <h4>
                        <a href="/service">AIR FREIGHT</a>
                      </h4>
                      <p>
                        Our airport-to-airport service is ideally suited for all
                        customers from one shipment or a high volume of business
                        requiring time-critical delivery.
                      </p>
                      <a href="/service" className="read-more">
                        read more <span>&gt;</span>
                      </a>
                    </div>
                  </div>
                  <div className="slides-tab zoom">
                    <figure>
                      <a href="/service">
                        <img
                          src="assests/images/CustomeClearance.jpeg"
                          alt=""
                        />
                      </a>
                    </figure>
                    <div className="slides-text">
                      <h4>
                        <a href="/service">Customs Clearance</a>
                      </h4>
                      <p>
                        All formalities till Cargo is out of charge and ready to
                        move to Warehouse.
                      </p>
                      <a href="/service" className="read-more">
                        read more <span>&gt;</span>
                      </a>
                    </div>
                  </div>
                  <div className="slides-tab zoom">
                    <figure>
                      <a href="/service">
                        <img src="assests/images/supllychain.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="slides-text">
                      <h4>
                        <a href="/service">Supply Chain and Logistics</a>
                      </h4>
                      <p>
                        We focus on solving your supply chain Needs from end to
                        end, taking the complexity out of container shipping for
                        you.
                      </p>
                      <a href="/service" className="read-more">
                        read more <span>&gt;</span>
                      </a>
                    </div>
                  </div>
                  <div className="slides-tab zoom">
                    <figure>
                      <a href="service">
                        <img src="assests/images/LandHaulage1.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="slides-text">
                      <h4>
                        <a href="service">
                          Surface Transportation & Cargo Handling
                        </a>
                      </h4>
                      <p>
                        Last mile & Road Transportation services from Port to
                        Customers Warehouse.
                      </p>
                      <a href="/service" className="read-more">
                        read more <span>&gt;</span>
                      </a>
                    </div>
                  </div>
                  <div className="slides-tab zoom">
                    <figure>
                      <a href="service">
                        <img src="assests/images/yard.jpg" alt="" />
                      </a>
                    </figure>
                    <div className="slides-text">
                      <h4>
                        <a href="Services.aspx">Project Handling</a>
                      </h4>
                      <p>
                        Special Equipments and all type of odd dimensional and
                        out of gauge cargo.
                      </p>
                      <a href="/service" className="read-more">
                        read more <span>&gt;</span>
                      </a>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
          <div className="features">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-4">
                  <div className="features-text">
                    <div className="heading">
                      <span>AMAZING FEATURES</span>
                      <h3>WE OFFER QUICK & POWERFUL SOLUTION FOR TRANSPORT</h3>
                    </div>
                    <p>We Are Here for all your Logistics Need.</p>
                    <a href="/service" className="services-link button">
                      our services
                    </a>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-8 custom-padding">
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <div className="features-tab">
                        <i className="icon-ship"></i>
                        <div className="tab-text">
                          <h5>QUICKEST CARGO</h5>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div className="features-tab">
                        <i className="icon-train"></i>
                        <div className="tab-text">
                          <h5>FAST AIR FREIGHT</h5>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div className="features-tab">
                        <i className="icon-plane"></i>
                        <div className="tab-text">
                          <h5>GROUND SHIPPING</h5>
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div className="col-xs-12 col-sm-6">
                      <div className="features-tab">
                        <i className="icon-clock"></i>
                        <div className="tab-text">
                          <h5>TIMELY DELIVERY</h5>
                          <p></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="about">
            <div className="figure parallax"></div>
            <div className="transport-king">
              <div className="about-us">
                <div className="description">
                  <div className="heading">
                    <span>LITTLE ABOUT US</span>
                    <h3>ABOUT ExpressCargo Movers</h3>
                  </div>
                  <p style={{ color: "white" }}>
                    Established in 2016 ExpressCargo is the flagship company of
                    a multifaceted corporate house and today has become a
                    conglomerate of individual business entities with a strong
                    presence in multi-modal international logistics. With our
                    impeccable financial dealings and standing in the trade, we
                    have carved for ourselves a niche place in Indian market.
                    Express Cargo Movers with its worldwide network of Efficient
                    Overseas Partners Strategically Located Offices is well
                    poised to deliver your cargo timely, safely and reliably in
                    an efficient manner. Having unmatched financial &
                    professional integrity Arise commands a High Level of
                    respect from its corporate and individual clients as a
                    Cohesive Team Work who Delivers Time and Again against under
                    ..
                    <a
                      href="AboutUs.aspx"
                      style={{ color: "white" }}
                      className="read-more"
                    >
                      know more about us <span>&gt;</span>
                    </a>
                  </p>
                </div>
              </div>
              <div className="testimonial-section">
                <blockquote className="custom-blockquote">
                  <div className="spanish">
                    <p style={{ color: "#104c91", letterSpacing: "0.3px" }}>
                      ExpressCargo Movers is committed to Total Customer
                      Satisfaction by providing Efficient and Cost-Effective
                      Solutions to our International Freight Forwarding Needs
                      and Fast Track Customs Clearance
                    </p>
                  </div>
                  <footer className="mission">
                    <h5>OUR MISSION STATEMENT</h5>
                    <span>MESSAGE FROM THE DESK OF OUR MANAGING DIRECTOR</span>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="query">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-sm-10">
                  <h5>DO YOU STILL HAVE A QUESTION REGARDING OUR SERVICES?</h5>
                  <p>
                    Send your query to us for better and personal suggestions
                    from our experts.
                  </p>
                </div>
                <div className="col-xs-12 col-sm-2">
                  <a href="/contactUs" className="contact-us button">
                    contact us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Main;
