import React from "react";

const Services = () => {
  return (
    <section id="section">
      {/* Section box starts Here */}
      <div className="section">
        <div style={{ background: "#f0f4f7" }}>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="heading">
                  <h3 style={{ textAlign: "center" }}>Our Services</h3>
                  <img
                    src="assests/images/serviceshead.jpeg"
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-md-12">
                <p>
                  International freight forwarding is often a balancing act
                  between time, cost and environmental concerns. That’s one of
                  the reasons companies choose Express Cargo Movers for cost
                  effective, smooth delivery of their cargo. Like them, you can
                  be confident that our strategic alliances around the globe
                  give you the flexibility to choose from a range of air and
                  ocean freight service options. Seamlessly tailored to your
                  precise needs, complete with end-to-end visibility.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="amazing-features" style={{ paddingTop: "50px" }}>
          <div className="container">
            <div
              className="row"
              style={{ marginBottom: "5px", background: "#f0f4f7" }}
            >
              <div className="col-xs-12 col-sm-12">
                <div className="heading">
                  <h3 style={{ padding: "10px", textAlign: "center" }}>
                    Customs Clearing Reassuring Customers
                  </h3>
                </div>
                <p>
                  We’re one of the leading Customs Broker, ensuring smooth and
                  easy customs clearance for all our customers, so that they
                  receive their goods on time.
                </p>
                <br clear="left" />
                <img
                  src="assests/images/CustomeClearance.jpeg"
                  alt=""
                  style={{ width: "50%", margin: "10px" }}
                  align="left"
                />
                <p>
                  Our Customs Brokers help ease Import and Export regulations
                  and paperwork in record time for all of your shipments.
                  Handling all the trade compliance and procedures, we help
                  clear consignments by sea, land and air more efficiently.
                </p>
                <h4>We will Handle All the Custom's Clearance Procedures</h4>
                <p className="mb-1">
                  All goods imported into (India) have to pass through the
                  procedure of customs for proper examination, appraisal,
                  assessment and evaluation. This helps the custom authorities
                  to charge the proper tax and also check the goods against the
                  illegal import.
                </p>
                <h4 className="mb-1">
                  Our Professional Team will Take care of Import customs
                  Clearance
                </h4>
                <ul
                  className="mb-1"
                  style={{ fontSize: "12px", listStyle: "none" }}
                >
                  <li>HSN classification</li>
                  <li>rate of duty</li>
                  <li>processing the customs clearance</li>
                  <li>assessment</li>
                  <li>
                    customs examination and obtaining customs out of charge and
                    arranging dispatch
                  </li>
                </ul>
                <a href="request-a-quote.aspx" className="button">
                  Request a Quote
                </a>
              </div>
            </div>
            <div
              className="row"
              style={{ marginBottom: "15px", background: "#f0f4f7" }}
            >
              <div className="col-xs-12 col-sm-8">
                <div className="amazing-text">
                  <div className="heading">
                    <h3>Ocean Freight</h3>
                  </div>
                  <p>
                    We offer efficient sea freight solutions customized to your
                    needs. From Full Container Loads (FCL) to Less than
                    Container Loads (LCL), on all major global routes, with
                    guaranteed transit times.
                  </p>
                  <a href="request-a-quote.aspx" className="button">
                    Request a Quote
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <img
                  style={{ paddingTop: "25px" }}
                  src="assests/images/oceanshippingimg.jpg"
                  alt=""
                />
              </div>
            </div>
            <div
              className="row"
              style={{ marginBottom: "15px", background: "#f0f4f7" }}
            >
              <div className="col-xs-12 col-sm-4">
                <img
                  style={{ paddingTop: "101px" }}
                  src="assests/images/AirCargo.jpg"
                  alt=""
                />
              </div>
              <div className="col-xs-12 col-sm-8">
                <div className="amazing-text">
                  <div className="heading">
                    <h3 style={{ padding: "10px" }}>Air Freight</h3>
                  </div>
                  <div className="list-group" style={{ background: "#f0f4f7" }}>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action flex-column align-items-start"
                      style={{ background: "#f0f3f6" }}
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <h4 className="mb-1">Fly high at low costs.</h4>
                      </div>
                      <p className="mb-1">
                        As a major global Air Freight Forwarding Company, we
                        take our delivers responsibly and seriously. Being one
                        of the leading air freight forwarders, we bring your
                        shipments on time and safely to any destination in the
                        world thanks to our strategic alliances with prime
                        carriers across all major commercial destinations.
                      </p>
                    </a>
                    <a
                      href="#"
                      className="list-group-item list-group-item-action flex-column align-items-start"
                      style={{ background: "#f0f3f6" }}
                    >
                      <div className="d-flex w-100 justify-content-between">
                        <h4 className="mb-1">
                          Air Freight Services for every kind of shipment
                        </h4>
                      </div>
                      <p className="mb-1">
                        We offer total solutions in sea-air, air-sea and air-air
                        services, with consolidation on all major trade routes.
                        We are fully equipped to handle charters for special
                        projects, oversized cargo and dangerous goods. Each
                        shipment is handled with care by our expert staff so
                        that your goods reach you safely no matter what.
                      </p>
                    </a>
                  </div>
                  <a href="request-a-quote.aspx" className="button">
                    Request a Quote
                  </a>
                </div>
              </div>
            </div>
            <div
              className="row"
              style={{ marginBottom: "5px", background: "#f0f4f7" }}
            >
              <div className="col-xs-12 col-sm-8">
                <div className="amazing-text">
                  <div className="heading">
                    <h3>Supply Chain and Logistics</h3>
                  </div>
                  <p>
                    We focus on solving your supply chain needs from End to End,
                    taking the complexity out of container shipping for you.
                  </p>
                  <a href="request-a-quote.aspx" className="button">
                    Request a Quote
                  </a>
                </div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <img
                  style={{ paddingTop: "25px" }}
                  src="assests/images/supplychain.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="query">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-10">
                <h5>DO YOU STILL HAVE A QUESTION REGARDING OUR SERVICES?</h5>
                <p>Get a Quote from us to know more about these services.</p>
              </div>
              <div className="col-xs-12 col-sm-2">
                <a href="request-a-quote.aspx" className="button">
                  Request a Quote
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section box ends Here */}
    </section>
  );
};

export default Services;
